import { Navigate, Route, Routes } from 'react-router-dom';
import routesData from './routes';
import { useAuth } from 'src/hooks';
import Default from './Default';
import RequireAuth from './RequireAuth';

const AppRouter = () => {
	const { token  } = useAuth();

	const { protectedRoutes, publicRoutes } = routesData;

	const publicPageRoutes = publicRoutes.map(({ label, path, component }) => {
		return <Route key={label} path={`/${path}`} element={component} />;
	});

	const protectedPageRoutes = protectedRoutes.map(({ label, path, component }) => {
		return <Route key={label} path={`/${path}`} element={component} />;
	});

	return (
		<Routes>
			<Route path="/" element={<Default />}>
				{/* public routes */}
				{!token  && <>{publicPageRoutes}</>}

				{/* protected routes */}
				<Route element={<RequireAuth />}>{protectedPageRoutes}</Route>

				{/* catch all */}
				{/* <Route path="*" element={<Navigate to={token ? '/post-job' : '/login'} />} /> */}
				<Route path="*" element={<Navigate to={token ? '/' : '/'} />} />
			</Route>
		</Routes>
	);
};

export default AppRouter;
