import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { Button, Typography } from 'src/components/common/Base';
import { ThemeBg, VectorHeadphone, Checked, GroupImage, RisingStars } from 'src/assets';
import { InnerPageBanner, AddressData } from 'src/components/common/Widgets';

// Set up EmailJS with your user ID and template ID
emailjs.init('jPAZ-WSmDjXn96NUr');

import './style.scss';

const ContactUsDefault = () => {

	const [formData, setFormData] = useState({
		name: '',
		number: '',
		email: '',
		message: ''
	});
	const [errors, setErrors] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};


	const handleSubmit = (e) => {
		e.preventDefault();
		const errors = validateForm(formData);
		if (Object.keys(errors).length === 0) {
			// Here you can handle form submission, e.g., sending data to backend
			console.log(formData);
			setIsSubmitted(true);

			// Send email using EmailJS
			emailjs.sendForm('service_tsp8aka', 'template_8a2enty', e.target, 'jPAZ-WSmDjXn96NUr')
				.then((result) => {
					console.log(result.text);
					alert('Message sent successfully!');
					// Optionally, clear the form after successful submission
					setFormData({ name: '', number: '', email: '', message: '' });
				}, (error) => {
					console.error('Error sending email:', error.text);
					alert('Error sending message. Please try again later.');
				});

		} else {
			setErrors(errors);
		}
	};

	const validateForm = (data) => {
		const errors = {};
		if (!data.name.trim()) {
			errors.name = 'Name is required';
		}
		if (!data.number.trim()) {
			errors.number = 'Number is required';
		}
		if (!data.email.trim()) {
			errors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(data.email)) {
			errors.email = 'Email address is invalid';
		}
		if (!data.message.trim()) {
			errors.message = 'Message is required';
		}
		return errors;
	};
	// const tabsData = [
	// 	{ title: 'Tab 1', content: 'Content of Tab 1' },
	// 	{ title: 'Tab 2', content: 'Content of Tab 2' },
	// 	{ title: 'Tab 3', content: 'Content of Tab 3' },
	// ];

	const pageTitle = 'Contact Us';
	const backgroundImageUrl = GroupImage;
	return (
		<>
			<div className='contact-us-page'>

				<InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />

				{/* <Address tabs={tabsData} /> */}
				<AddressData />

				{/* <LaunchYouBusiness /> */}

				<section className='contactUs-Sec paddingY-100'>
					<div className="ah-container">
						<div className="row justify-content-center">
							<div className="col-lg-8 bg-color-box bg-light-gray padding-50-100">
								<div className="description text-center">
									<div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Talk with us</span></div>
									<br />
									<h2>We're Available To Help <span>Anytime, Anywhere</span></h2>
									<p>Our dedicated team is here to support you 24/7, ensuring you receive the assistance you need, whenever and wherever you need it. Experience unmatched service and expertise with Genius System Logix.</p>
								</div>
								<div>
									{!isSubmitted ? (
										<form onSubmit={handleSubmit}>
											<div className="row">
												<div className="col-md-6">
													<div className='form-group'>
														<label htmlFor="name">Name<sup>*</sup>:</label>
														<input
															type="text"
															id="name"
															name="name"
															value={formData.name}
															onChange={handleChange}
														/>
														{errors.name && <span className='for-error'>{errors.name}</span>}
													</div>
													<div className='form-group'>
														<label htmlFor="number">Number<sup>*</sup>:</label>
														<input
															type="number"
															id="number"
															name="number"
															value={formData.number}
															onChange={handleChange}
														/>
														{errors.number && <span className='for-error'>{errors.number}</span>}
													</div>
													<div className='form-group'>
														<label htmlFor="email">Email<sup>*</sup>:</label>
														<input
															type="email"
															id="email"
															name="email"
															value={formData.email}
															onChange={handleChange}
														/>
														{errors.email && <span className='for-error'>{errors.email}</span>}
													</div>
												</div>
												<div className="col-md-6">
													<div className='form-group'>
														<label htmlFor="message">Message<sup>*</sup>:</label>
														<textarea
															className='full-height'
															id="message"
															name="message"
															value={formData.message}
															onChange={handleChange}
														/>
														{errors.message && <span className='for-error'>{errors.message}</span>}
													</div>
												</div>
												<div className="col-md-12 mt-4">
													{/* <button type="submit">Submit</button> */}
													<div className="form-group mb-0 mbl-center">
														<Button className={`btn primary desktop animate__fadeInUp animate__animated animate__slow`} color={'link'} variant={'solid'}>
															<Typography variant="button-text">Let's Talk</Typography>
														</Button>
													</div>
												</div>
											</div>
										</form>
									) : (
										<div className='text-center'>
											<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '200px', height: 'auto' }} src={Checked} alt="Checked" />
											<h3>Thank you for your <span>submission!</span></h3>
										</div>
									)}
								</div>
							</div>
							<div className="col-lg-4 bg-color-box bg-theme p-50 bg-cover" style={{ backgroundImage: `url(${ThemeBg})` }} data-aos="zoom-out" data-aos-easing="linear" data-aos-duration="500">
								<div className="description">
									<h2 className='text-white'>Say Hi There!</h2>
									<p className='text-white'>For any inquiries, please contact us. Our team is ready to assist you!</p>
									<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '100%', height: 'auto' }} src={VectorHeadphone} alt="VectorHeadphone" />
									<h3 className='text-white mt-4'>Mail Us</h3>
									<ul className="ah-list-items ah-inline-items">
										{/* <li className="ah-icon-list-item ah-inline-item pb-0">
											<Link className="d-link ah-icon-list-text pb-0" to={'mailto:info@geniuslogix.com'}>
												<Typography variant="link-text">
													contact@geniuslogix.com,
												</Typography>
											</Link>
										</li> */}
										<li className="ah-icon-list-item ah-inline-item">
											<Link className="d-link ah-icon-list-text" to={'mailto:info@geniuslogix.com'}>
												<Typography variant="link-text">
													info@geniuslogix.com
												</Typography>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default ContactUsDefault;
