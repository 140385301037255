import React from 'react';
// import { Link } from 'react-router-dom';
// import { CustomerSupport, LogoDesigns, ResponsiveWebDesign, BusinessConsulting, SearchEngineOptimization } from 'src/assets';
// import { Typography, Button } from 'src/components/common/Base';
import { InnerPageBanner } from 'src/components/common/Widgets';
import ServicesListDefault from 'src/components/common/Widgets/ServiceDetails/index';
import { GroupImage } from 'src/assets';
import './style.scss';

const ServicesPage = () => {

    const backgroundImageUrl = GroupImage;
    const pageTitle = 'Services';

    return (
        <>
            <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />

            <ServicesListDefault/>
        </>
    );
};

export default ServicesPage;