import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks';

const RequireAuth = () => {
	const { id } = useAuth();
	const location = useLocation();

	return id ? <Outlet /> : <Navigate to="/coming-soon" state={{ from: location }} replace />;
};

export default RequireAuth;
