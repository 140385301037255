import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logo, toggle, WhiteLogo } from 'src/assets';
import { Button, Typography } from '../../Base';
import './style.scss';
import PropTypes from 'prop-types';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Navbar = ({ isComingSoon }) => {
    const [isSticky, setIsSticky] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 200);
            
            if (offset > lastScrollY && isNavOpen) {
                setIsNavOpen(false); // Close nav if scrolling up
            }
            setLastScrollY(offset);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavOpen, lastScrollY]);

    useEffect(() => {
        const loggedInUserId = localStorage.getItem("loggedInUserId");
        setIsLoggedIn(!!loggedInUserId);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("loggedInUserId");
        setIsLoggedIn(false);
        navigate('/login');
    };

    const toggleNav = () => {
        setIsNavOpen(prev => !prev);
    };

    const handleLinkClick = () => {
        setIsNavOpen(false);
    };

    return (
        <nav className={`navbar navbar-expand-lg bg-body-tertiary ${isSticky ? 'sticky' : ''}`}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src={WhiteLogo} alt="WhiteLogo" width={120} className="for-stuck-nav img-fluid logo me-3" />
                    <img src={logo} alt="Logo" width={120} className="for-sticky-nav img-fluid logo me-3" />
                </Link>
                <button
                    className={`navbar-toggler ${isComingSoon ? 'invisible' : ''}`}
                    type="button"
                    onClick={toggleNav}
                    aria-expanded={isNavOpen}
                    aria-label="Toggle navigation"
                >
                    <img src={toggle} alt="Toggle menu" />
                </button>

                <div className={`collapse navbar-collapse ${isComingSoon ? 'invisible' : ''} ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/about-us" onClick={handleLinkClick}>
                                <Typography variant="small-text">About Us</Typography>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/services" onClick={handleLinkClick}>
                                <Typography variant="small-text">Services</Typography>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/careers" onClick={handleLinkClick}>
                                <Typography variant="small-text">Careers</Typography>
                            </Link>
                        </li>
                        <li className="nav-item d-lg-none">
                            <Link className="nav-link" to="/contact-us" onClick={handleLinkClick}>
                                <Typography variant="small-text">Contact Us</Typography>
                            </Link>
                        </li>
                        {isLoggedIn && (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/post-job" onClick={handleLinkClick}>
                                        <Typography variant="small-text">Post Job</Typography>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us" onClick={handleLinkClick}>
                                        <Typography variant="small-text">Contact Us</Typography>
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
                <div className="signup">
                    {isLoggedIn ? (
                        <Button className="btn d-btn" color="primary" onClick={handleLogout}>
                            <Typography variant="button-text">Logout</Typography>
                        </Button>
                    ) : (
                        <Link to="/contact-us">
                            <Button className="btn d-btn" color="primary">
                                <Typography variant="button-text">Contact Us</Typography>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
        </nav>
    );
};

Navbar.propTypes = {
    isComingSoon: PropTypes.bool,
};

export default Navbar;
