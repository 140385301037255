import LandingPage from 'src/pages/landing-page';
import AboutUs from 'src/pages/about-us';
import ContactUs from 'src/pages/contact-us';
import ServiceDetails from 'src/pages/service-details';
import ServicesPage from 'src/pages/services-page';
import CareersPage from 'src/pages/careers-page';
import CareerPostDetails from 'src/pages/career-post-details';
// new
import JobFormPageListPage from 'src/pages/post-job';
// import JobDetailsDefaultPage from 'src/pages/post-job-details';
import LoginPage from 'src/pages/login-page';
import RegistrationPage from 'src/pages/registration-page';
import JobPostDetails from 'src/components/common/Widgets/PostJob/JobPostDetails';


export const routes = {
	landingPage: {
		path: '/',
		label: 'Landing Page',
		component: <LandingPage />,
		private: false
	},
	AboutUs: {
		path: '/about-us',
		label: 'About Us',
		component: <AboutUs />,
		private: false
	},
	ServicesPage: {
		path: '/services',
		label: 'Services',
		component: <ServicesPage />,
		private: false
	},
	ServiceDetails: {
		path: '/services/:id',
		label: 'Service Details',
		component: <ServiceDetails />,
		private: false
	},
	CareersPage: {
		path: '/careers',
		label: 'Careers Page',
		component: <CareersPage />,
		private: false
	},
	CareerPostDetails: {
		path: '/career/:jobId',
		label: 'Job Post Details',
		component: <CareerPostDetails />,
		private: false
	},
	contactUs: {
		path: '/contact-us',
		label: 'Contact Us',
		component: <ContactUs />,
		private: false
	},
	JobFormPageListPage: {
		path: '/post-job',
		label: 'Job Post Page',
		component: <JobFormPageListPage />,
		private: false
	},
	JobPostDetails: {
		// path:"/post-job-details",
		path:"/post-details",
		label: 'Post Job Details',
		component: <JobPostDetails />,
		private: false
	},
	LoginPage: {
		// path:"/post-job-details",
		path:"/login",
		label: 'Login',
		component: <LoginPage />,
		private: false
	},
	RegistrationPage: {
		// path:"/post-job-details",
		path:"/registration",
		label: 'Registration',
		component: <RegistrationPage />,
		private: false
	},
};

const routesData = {
	defaultRoutes: [],
	protectedRoutes: [],
	// publicRoutes: [routes.comingSoon, routes.landingPage, routes.AboutUs, routes.careers, routes.subscriptionConfirmation, routes.commonQuestions, routes.customers, routes.superProducers, routes.seeStories, routes.whatsNew, routes.ServicesPage ,routes.ServicesList, routes.ServiceDetails, routes.contactUs]
	publicRoutes: [routes.landingPage, routes.AboutUs, routes.ServicesPage, routes.ServiceDetails, routes.CareersPage, routes.CareerPostDetails, routes.contactUs, routes.JobPostDetails, routes.JobFormPageListPage, routes.LoginPage, routes.RegistrationPage]
};

export default routesData;