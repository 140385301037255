import { Footer, Navbar } from 'src/components/common/Layout';
import AppRouter from './AppRouter';
import { useLocation } from 'react-router-dom';

const DefaultLayout = () => {
	const location = useLocation();
	return (
		<>
			{/* <Navbar isComingSoon={location.pathname.includes('/coming-soon') || location.pathname.includes('/subscription-confirmation') ? true : false} /> */}
			<Navbar isComingSoon={location.pathname.includes('/coming-soon') || location.pathname.includes('/subscription-confirmation') ? true : false} />
			<AppRouter />
			<Footer showLargeFooter={location.pathname.includes('/coming-soon') || location.pathname.includes('/subscription-confirmation') ? false : true} />
			{/* <Footer showLargeFooter={location.pathname.includes('/coming-soon') || location.pathname.includes('/subscription-confirmation') ? false : true} /> */}
		</>
	);
};
// /coming-soon
export default DefaultLayout;
