import { Link } from 'react-router-dom';
import { Services } from './ServicesData';
import { RisingStars } from 'src/assets';
import './style.scss';

const ServicesListDefault = () => {

    return (
        <>
            <section className="services-sec">
                <div className="ah-container-services">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="description">
                            <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Services</span></div>
                            <br/>
                                <h2>With Our <span>Cloud-Based <br /> Solutions, </span>You Can Grow Faster</h2>
                                <p>Genius System Logix provides the comprehensive digital suite of products and services as per clients expectations, summary of range of products is numerated in ensuing headings.</p>
                            </div>
                        </div>
                        <div className='w-100'></div>
                        {Services.map((service) => (
                            <div key={service.id} className="col-lg-4 col-md-6">
                                <div className="service-box">
                                    <div className="icon">
                                        <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '55px', height: '55px' }} src={service.icon} alt={service.icon} />
                                    </div>
                                    <h3>{service.title}</h3>
                                    <p>{service.description}</p>
                                    <Link className="d-link" to={`/Services/${service.id}`}>View More <i>&#8594;</i></Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesListDefault;