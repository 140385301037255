import React from 'react';
import Address from '.';
import "./style.scss";

const AddressData = () => {
    const tabsData = [
        {
            title: 'Pakistan',
            content: 'Located in Clifton, Karachi, our software house offers cutting-edge web development, mobile app solutions, and custom software tailored to meet your business needs. We specialize in delivering high-quality digital services with a focus on innovation and client satisfaction.',
            cityName: 'Pakistan',
            address: 'Office 302, Ahmed Arcade, near Clifton Center, Block 5 Clifton, Karachi, Sindh 75600',
            phone1: '0300 0920766',
            // phone2: '+92-21-35300262',
            email1: 'contact@geniuslogix.com',
            // email2: 'info@geniuslogix.com',
            mapLocation: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2153.1198796564554!2d67.03344350221296!3d24.826207338436024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33d4678e6dc51%3A0xddb969dfc91af5dd!2sGenius%20Logix%20(Genius%20System%20Logix%20Pvt%20Ltd)!5e0!3m2!1sen!2s!4v1728887544908!5m2!1sen!2s"
        },
        // {
        //     title: 'UAE',
        //     content: 'Discover cutting-edge solutions at our UAE office, where our team is committed to driving your business success in the vibrant city of Dubai.',
        //     cityName: 'UAE',
        //     address: 'UAE Suite # 101 Ahmed Arcade, Block 5, Clifton , Karachi, Pakistan.',
        //     phone1: '+123456789', phone2: '+123456789',
        //     email1: 'contact@geniuslogix.com', email2: 'info@geniuslogix.com',
        //     mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2221836.3825186025!2d52.38390893471634!3d23.800121810570126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e48dfb1ab12bd%3A0x33d32f56c0080aa7!2sUnited%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1710993949410!5m2!1sen!2s"
        // },
        // {
        //     title: 'CHINA',
        //     content: 'Unlock unparalleled opportunities at our China office, providing expert services and innovative solutions in the bustling metropolis of Beijing.',
        //     cityName: 'China',
        //     address: 'CHINA Suite # 101 Ahmed Arcade, Block 5, Clifton , Karachi, Pakistan.',
        //     phone1: '+987654321', phone2: '+987654321',
        //     email1: 'contact@geniuslogix.com', email2: 'info@geniuslogix.com',
        //     mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5835717.433544659!2d100.30852811326797!3d34.43751994526582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31508e64e5c642c1%3A0x951daa7c349f366f!2sChina!5e0!3m2!1sen!2s!4v1710827778546!5m2!1sen!2s"
        // },
    ];
    return (
        <div>
            <Address tabs={tabsData} />
        </div>
    );
};

export default AddressData;