import React, { useState } from 'react';
import { ChevronUp, ChevronDown } from 'react-feather';
import PropTypes from 'prop-types';
import './styles.scss';
import { Typography } from '..';

const Accordion = ({ title, content }) => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			// Trigger your click handler on Enter or Space key press
			handleClick();
		}
	};

	return (
		<div className="accordion-container">
			<div className="title-icon" onClick={handleClick} onKeyPress={handleKeyPress} role="button" tabIndex="0">
				<Typography variant={'body-2'} className="accordion-title">
					{title}
				</Typography>
				<div className="arrow-icon">{open ? <ChevronUp /> : <ChevronDown />}</div>
			</div>
			{open && (
				<Typography className="content" variant={'body-1'}>
					{content}
				</Typography>
			)}
			{/* <div className="accordion-divider"></div> */}
		</div>
	);
};

Accordion.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string
};

export default Accordion;