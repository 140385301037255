import React from 'react';
// import { Link } from 'react-router-dom';
// import { BrandIcon1, BrandIcon2, BrandIcon3, BrandIcon4, Product, } from 'src/assets';
// import { Typography, Button } from 'src/components/common/Base';
import { AccelerateYouBusinessVideo, RisingStars } from 'src/assets';
import { Typography, Button } from 'src/components/common/Base';
import './style.scss';

const AccelerateYourBusiness = () => {

    return (
        <>
            <section className="accelerateYourBusiness-sec">
                <video autoPlay loop muted className="background-video">
                    <source src={AccelerateYouBusinessVideo} type="video/mp4" />
                    {/* Add additional source elements for other video formats if needed */}
                    Your browser does not support the video tag.
                </video>
                <div className="ah-container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 sol-sm-12">
                            <div className="description text-center mb-0">
                                <div className='badge white'><img src={RisingStars} alt="RisingStars" /><span>YOUR BUSINESS</span></div>
                                <h1 className="text-white">Crafting Digital Excellence</h1>
                                <p>Fueled by groundbreaking ideas and advanced technology, we engineer the ultimate user experience to elevate businesses globally.</p>
                                {/* <Button className={`btn primary m-auto mx-4 desktop animate__fadeInUp animate__animated animate__slow`} color={'link'} variant={'solid'}>
                                    <Typography variant="button-text">More Details</Typography>
                                </Button> */}
                                <a href="#twoSideFullWidthSec" className='desktop animate__fadeInUp animate__animated animate__slow'>
                                    <Button className="btn d-btn" color="primary">
                                        <Typography variant="button-text">More Details</Typography>
                                    </Button>
                                </a>
                                {/* <Link to="/contact-us" className='desktop animate__fadeInUp animate__animated animate__slow'>
                                    <Button className="btn d-btn" color="primary">
                                        <Typography variant="button-text">Contact Us</Typography>
                                    </Button>
                                </Link> */}
                            </div>
                        </div>
                        <div className='col-md-12 text-center'>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AccelerateYourBusiness;