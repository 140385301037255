import React from 'react';
import { Link } from 'react-router-dom';
import { RisingStars } from 'src/assets';
import { Typography, Button } from 'src/components/common/Base';


import './style.scss';

// const TwoSideFullWidthSection = ({backgroundImage, title}) => {
const TwoSideFullWidthSection = ({twoSideSecLeftSideImg, twoSideSecBadge, twoSideSecTitle, twoSideSecDescription, twoSideSecList1, twoSideSecList2, twoSideSecList3,}) => {
    return (
        <>
            {/* {twoSideSection.map(twoSideSec => ( */}
                {/* <section key={twoSideSec.id} className="twoSideFullWidthSec bg-cover"> */}
                <section className="twoSideFullWidthSec bg-cover" id="twoSideFullWidthSec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inner-box left-inner-box bg-theme-light mb-0">
                                    <div>
                                        <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '100%', height: 'auto' }} src={twoSideSecLeftSideImg} alt={twoSideSecLeftSideImg} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner-box mb-0">
                                    <div>
                                        <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>{twoSideSecBadge}</span></div>
                                        <br/>
                                        <h2>{twoSideSecTitle}</h2>
                                        <p className='ah-mb-50'  dangerouslySetInnerHTML={{ __html: twoSideSecDescription }} ></p>
                                        <ul className="ah-list-items ah-mb-100">
                                            <li className="ah-list-item">
                                                <span className="ah-list-text">{twoSideSecList1}</span>
                                            </li>
                                            <li className="ah-list-item">
                                                <span className="ah-list-text">{twoSideSecList2}</span>
                                            </li>
                                            <li className="ah-list-item">
                                                <span className="ah-list-text">{twoSideSecList3}</span>
                                            </li>
                                        </ul>
                                        <Link to="/about-us" className='desktop animate__fadeInUp animate__animated animate__slow'>
                                            <Button className="btn d-btn" color="primary">
                                                <Typography variant="button-text">Read More</Typography>
                                            </Button>
                                        </Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* ))}; */}
        </>
    );
};

export default TwoSideFullWidthSection;