import React from 'react';
// import { Link } from 'react-router-dom';
// import { CustomerSupport, LogoDesigns, ResponsiveWebDesign, BusinessConsulting, SearchEngineOptimization } from 'src/assets';
// import { Typography, Button } from 'src/components/common/Base';
// import { InnerPageBanner, CareerPostDetails, CareerPostForm, CareerPostList } from 'src/components/common/Widgets';
import { InnerPageBanner, CareerPostList } from 'src/components/common/Widgets';

import { GroupImage } from 'src/assets';
import './style.scss';

const Careers = () => {
    const backgroundImageUrl = GroupImage;
    const pageTitle = 'Careers';
    // Banner End



    return (
        <>
            <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />
            <CareerPostList />
            {/* <div>
                <h1>Job Board</h1>
                <CareerPostForm onAddPost={addPost} />
                <CareerPostList posts={posts} onShowDetails={showDetails} />
                {selectedPost && <CareerPostDetails post={selectedPost} />}
            </div> */}
        </>
    );
};

export default Careers;
