import React from 'react';
import { GroupImage } from 'src/assets';
import { InnerPageBanner, RegistrationPage } from 'src/components/common/Widgets';
import './style.scss';
// import './libs.js';

const RegistrationPageDefault = () => {
    
    // Inner Banner
    const pageTitle = 'Registration Page';
    const backgroundImageUrl = GroupImage;

    return (
        <>
            <div>
                <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle}/>
            </div>
            <div>
                <RegistrationPage />
            </div>
        </>
    );
};

export default RegistrationPageDefault;
