import React from 'react';
// import { Link } from 'react-router-dom';
// import { CustomerSupport, LogoDesigns, ResponsiveWebDesign, BusinessConsulting, SearchEngineOptimization } from 'src/assets';
// import { Typography, Button } from 'src/components/common/Base';
import { InnerPageBanner, JobPostList } from 'src/components/common/Widgets';

import { GroupImage } from 'src/assets';
import './style.scss';

const JobFormPageList = () => {

    const backgroundImageUrl = GroupImage;
    const pageTitle = 'Post a new job';

    return (
        <>
            <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />
            <JobPostList/>
        </>
    );
};

export default JobFormPageList;


// JobForm.js
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { Button, Typography } from 'src/components/common/Base';
// import { InnerPageBanner } from 'src/components/common/Widgets';
// import { GroupImage, RisingStars, Checked } from 'src/assets';
// import './style.scss';

// const JobForm = () => {
//     const [formData, setFormData] = useState({
//         id: '',
//         title: '',
//         thumbnail: '',
//         description: '',
//         summaryDescription: '',
//         responsibilitiesListItem: [{ listItems: '' }],
//         skillsDescriptionList: [{ listItems: '' }],
//         aboutJobInfo: [
//             { aboutJobLabel: 'Experience', aboutJobLabelInfo: '' },
//             { aboutJobLabel: 'No of Vacancies', aboutJobLabelInfo: '' },
//             { aboutJobLabel: 'Working Hours', aboutJobLabelInfo: '' },
//             { aboutJobLabel: 'Working Days', aboutJobLabelInfo: '' },
//             { aboutJobLabel: 'Salary', aboutJobLabelInfo: '' },
//             { aboutJobLabel: 'Deadline', aboutJobLabelInfo: '' },
//         ],
//     });

//     const [jobs, setJobs] = useState([]);
//     const [deleteConfirmation, setDeleteConfirmation] = useState(false);
//     const [jobToDelete, setJobToDelete] = useState(null);
//     const [errors, setErrors] = useState({});
//     const [isSubmitted, setIsSubmitted] = useState(false);

//     const backgroundImageUrl = GroupImage;
//     const pageTitle = 'Job Form';

//     useEffect(() => {
//         fetchJobs();
//     }, []);

//     const fetchJobs = async () => {
//         try {
//             const response = await fetch('http://localhost:3001/jobs');
//             const data = await response.json();
//             setJobs(data);
//         } catch (error) {
//             console.error('Error fetching jobs:', error);
//         }
//     };

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.id]: e.target.value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             setIsSubmitted(true);
//             const response = await axios.post('http://localhost:3001/jobs', formData);
//             console.log('Job added successfully:', response.data);
//             fetchJobs();
//             setFormData({
//                 title: '',
//                 thumbnail: '',
//                 description: '',
//                 summaryDescription: ''
//             });
//         } catch (error) {
//             setErrors(errors);
//             console.error('Error adding job:', error);
//         }
//     };

//     const handleDeleteConfirmation = (id) => {
//         setJobToDelete(id);
//         setDeleteConfirmation(true);
//     };

//     const handleDelete = async () => {
//         if (jobToDelete) {
//             try {
//                 await axios.delete(`http://localhost:3001/jobs/${jobToDelete}`);
//                 console.log('Job deleted successfully');
//                 fetchJobs();
//                 setDeleteConfirmation(false);
//             } catch (error) {
//                 console.error('Error deleting job:', error);
//             }
//         }
//     };

//     return (
//         <>
//             <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />

//             <section className="services-sec pb-0">
//                 <div className="ah-container">
//                     <div className="row justify-content-center">
//                         <div className="col-md-6">
//                             <div className="description">
//                                 <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Services</span></div>
//                                 <br />
//                                 <h2>All <span>Jobs</span></h2>
//                                 <p>Genius System Logix provides the comprehensive digital suite of products and services as per clients expectations, summary of range of products is numerated in ensuing headings.</p>
//                             </div>
//                         </div>
//                         <div className='w-100'></div>
//                         {jobs.map(post => (
//                             <div key={post.id} className='col-md-4 p-relative'>
//                                 <Link className="d-link" to={`/job/${post.id}`}>
//                                     <div className='job-box'>
//                                         <div className='short-description'>
//                                             <h3 className=''>{post.title}</h3>
//                                             <div className='info'><span>Remote</span> - <span>Pakistan/Karachi</span> - <span>Full Time</span></div>
//                                         </div>
//                                         <div className="icon-container">
//                                             <span className='icon'>&#10148;</span>
//                                         </div>
//                                     </div>
//                                 </Link>
//                                 <button onClick={() => handleDeleteConfirmation(post.id)} className='remove-icon'>&#10539;</button>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </section>

//             {deleteConfirmation && (
//                 <div className="confirmation-modal">
//                     <p>Are you sure you want to delete this job?</p>
//                     <button onClick={handleDelete}>Yes</button>
//                     <button onClick={() => setDeleteConfirmation(false)}>No</button>
//                 </div>
//             )}

//             <section className='contactUs-Sec paddingY-100'>
//                 <div className="ah-container">
//                     <div className="row justify-content-center">
//                         <div className="col-md-12 bg-color-box bg-light-gray padding-70-100">
//                             <div className="description text-center">
//                                 <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Job</span></div>
//                                 <br />
//                                 <h2>We're Available To Help <span>Anytime, Anywhere</span></h2>
//                                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
//                             </div>
//                             <div>
//                                 {!isSubmitted ? (
//                                     <form onSubmit={handleSubmit}>
//                                         <div className="row">
//                                             <div className="col-md-6">
//                                                 <div className='form-group'>
//                                                     <label htmlFor="title">Job Title<sup>*</sup>:</label>
//                                                     <input
//                                                         type="text"
//                                                         id="title"
//                                                         value={formData.title}
//                                                         onChange={handleChange}
//                                                         required
//                                                     />
//                                                     {errors.name && <span>{errors.name}</span>}
//                                                 </div>
//                                                 <div className='form-group'>
//                                                     <label htmlFor="description">Description<sup>*</sup>:</label>
//                                                     <textarea
//                                                         id="description"
//                                                         value={formData.description}
//                                                         onChange={handleChange}
//                                                         required
//                                                     />
//                                                     {errors.message && <span>{errors.message}</span>}
//                                                 </div>
//                                                 <div className='form-group'>
//                                                     <label htmlFor="summaryDescription">Summary Description<sup>*</sup>:</label>
//                                                     <textarea
//                                                         id="summaryDescription"
//                                                         value={formData.summaryDescription}
//                                                         onChange={handleChange}
//                                                         required
//                                                     />
//                                                     {errors.message && <span>{errors.message}</span>}
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-12 mt-3">
//                                                 <div className="form-group mb-0">
//                                                     <Button className={`btn primary desktop animate__fadeInUp animate__animated animate__slow`} color={'link'} variant={'solid'}>
//                                                         <Typography variant="button-text">Let's Talk</Typography>
//                                                     </Button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </form>
//                                 ) : (
//                                     <div className='text-center'>
//                                         <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '200px', height: 'auto' }} src={Checked} alt="Checked" />
//                                         <br />
//                                         <h3>Your job has been <span>posted.</span></h3>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default JobForm;