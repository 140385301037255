import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
const Typography = ({ children, variant, className }) => {
	const getClassName = () => {
		switch (variant) {
			case 'h1':
				return 'header-1';
			case 'h2':
				return 'header-2';
			case 'h3':
				return 'header-3';
			case 'subtitle':
				return 'subtitle';
			case 'body-1':
				return 'body-1';
			case 'body-2':
				return 'body-2';
			case 'small-text':
				return 'small-text';
			case 'pre-title':
				return 'pre-title';
			case 'button-text':
				return 'button-text';
			case 'link-text':
				return 'link-text';
			default:
				return '';
		}
	};

	return <div className={`${getClassName()} ${className || ''}`}>{children}</div>;
};

Typography.propTypes = {
	children: PropTypes.node.isRequired,
	variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'subtitle', 'body-1', 'body-2', 'small-text', 'pre-title', 'button-text', 'link-text']),
	className: PropTypes.string
};

export default Typography;
