import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Button = ({ className, style, children, variant, color, onClick }) => {
	return (
		<button className={`btn ${color}${variant ? '-' + variant : ''} ${className ?? ''}`} style={style} onClick={onClick}>
			{children}
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.node.isRequired,
	variant: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func
};

export default Button;
