import React from 'react';
// import { Link } from 'react-router-dom';
// import { Typography } from 'src/components/common/Base';
// import { Typography, Accordion } from 'src/components/common/Base';
import { RisingStars, AboutUsMedia } from 'src/assets';
import { Accordion } from 'src/components/common/Base';
import { AccelerateYourBusiness, TwoSideFullWidthSection, OurBrand, LaunchYouBusiness, Partner,  } from 'src/components/common/Widgets';
import ServicesListDefault from 'src/components/common/Widgets/ServiceDetails/index';
import './style.scss';

// import { PercentageText } from 'src/components/common/Widgets';
// import { BlueSection } from 'src/components/common/Layout';

const LandingPage = () => {

	const sectionsData = [
		{
			// heading: 'Overview',
			accordions: [
				{
					title: 'How is GSL unique?',
					content: 'GSL stands out by providing innovative, tailored software solutions that cater specifically to the unique needs of businesses, delivered by a team of experienced and dedicated experts.'
				},
				{
					title: 'What happens if I’m not satisfied with GSL SuperProducers?',
					content: "If you're not satisfied with GSL SuperProducers, we offer a satisfaction guarantee, working closely with you to address any issues and ensure the solution meets your expectations or providing alternative resolutions."
				},
				{
					title: 'Are there any hidden fees? ',
					content: 'No, GSL operates with complete transparency; there are no hidden fees. All costs are clearly outlined upfront, ensuring you have a full understanding of the pricing structure.'
				}
			]
		},
		// {
		// 	heading: 'Geography',
		// 	accordions: [{ title: 'How is GSL unique?', content: 'Hello World 1' }]
		// }

		// Add more sections as needed
	];

	const twoSideSecLeftSideImg = AboutUsMedia;
	const twoSideSecBadge = "About US";
	const twoSideSecTitle = 'Change The Way You Do Business With Our Brand';
	const twoSideSecDescription = 'Our journey began in 2016 with a team of seven visionaries. Since then, we have transformed into a comprehensive mobile-centric software development company. Our success is marked by the highest team satisfaction in the industry, recognition from independent analysts, and a growing list of delighted clients. Some notable collaborations include Qvise Pvt Ltd, Tramiq International Ltd, Space Time International LTD UK, and Tramtech Solution Ltd, UK. Our expertise extends to assisting numerous startups in adopting cutting-edge technologies, ensuring they stay at the forefront of innovation. Choose Genius System Logix for a partnership that turns concepts into reality and propels your business into the future.';
	const twoSideSecList1 = 'Genius System Logix specializes in innovative, tailored software solutions.';
	const twoSideSecList2 = 'Our expert team delivers high-quality and reliable software products.';
	const twoSideSecList3 = 'We prioritize customer satisfaction with personalized services and continuous support.';


	return (
		<>
			<div className="landing-page-wrap">

				{/* <section className="services-sec">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-md-6">
								<div className="description">
									<div className="badge">CLOUD BASED</div>
									<h2>With Our <span>Cloud-Based <br /> Solutions, </span>You Can Grow Faster</h2>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
								</div>
							</div>
							<div className='w-100'></div>
							<div className="col-md-4">
								<div className="service-box">
									<div className="icon">
										<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '55px', height: '55px' }} src={CustomerSupport} alt="CustomerSupport" />
									</div>
									<h3>Customer Support</h3>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
									<Link className="d-link">View More <i>&#8594;</i></Link>
								</div>
							</div>
							<div className="col-md-4">
								<div className="service-box">
									<div className="icon">
										<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '55px', height: '55px' }} src={CustomerSupport} alt="CustomerSupport" />
									</div>
									<h3>Customer Support</h3>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
									<Link className="d-link">View More <i>&#8594;</i></Link>
								</div>
							</div>
							<div className="col-md-4">
								<div className="service-box">
									<div className="icon">
										<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '55px', height: '55px' }} src={CustomerSupport} alt="CustomerSupport" />
									</div>
									<h3>Customer Support</h3>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
									<Link className="d-link">View More <i>&#8594;</i></Link>
								</div>
							</div>
							<div className="col-md-4">
								<div className="service-box">
									<div className="icon">
										<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '55px', height: '55px' }} src={CustomerSupport} alt="CustomerSupport" />
									</div>
									<h3>Customer Support</h3>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
									<Link className="d-link">View More <i>&#8594;</i></Link>
								</div>
							</div>
							<div className="col-md-4">
								<div className="service-box">
									<div className="icon">
										<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '55px', height: '55px' }} src={CustomerSupport} alt="CustomerSupport" />
									</div>
									<h3>Customer Support</h3>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
									<Link className="d-link">View More <i>&#8594;</i></Link>
								</div>
							</div>
							<div className="col-md-4">
								<div className="service-box">
									<div className="icon">
										<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '55px', height: '55px' }} src={CustomerSupport} alt="CustomerSupport" />
									</div>
									<h3>Customer Support</h3>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
									<Link className="d-link">View More <i>&#8594;</i></Link>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				{/* <div className='h-200 bg-dark'></div> */}
				<div>
					<AccelerateYourBusiness />
				</div>
				<div>
					{/* <TwoSideFullWidthSection backgroundImage={backgroundImageUrl} title={pageTitle} /> */}
					<TwoSideFullWidthSection
						twoSideSecLeftSideImg = {twoSideSecLeftSideImg}
						twoSideSecBadge={twoSideSecBadge}
						twoSideSecTitle={twoSideSecTitle}
						twoSideSecDescription={twoSideSecDescription}
						twoSideSecList1={twoSideSecList1}
						twoSideSecList2={twoSideSecList2}
						twoSideSecList3={twoSideSecList3}
					/>
				</div>

				<div>
					<ServicesListDefault />
				</div>
				
				<div>
					<OurBrand />
				</div>

				<div>
					<Partner />
				</div>
				<div>
					<LaunchYouBusiness />
				</div>

				<section className='asked-questions ah-py-100'>
					<div className="ah-container common-questions-container">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-10">
								<div className="description text-center mb-5">
									<div className='badge'><img src={RisingStars} alt="RisingStars" /><span>OUR EXPERT'S ANSWER</span></div>
									<br/>
									<h2>Commonly Asked <span>Questions</span></h2>
									<p>GSL stands out by providing innovative, tailored software solutions delivered by a dedicated team of experts. If you're not satisfied with GSL SuperProducers, we offer a satisfaction guarantee with transparent, no hidden fees.</p>
								</div>
							</div>
							<div className="col-md-10">
								<div className="accordions" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">
									{sectionsData.map((section, index) => (
										<div className="accordions-containers" key={index}>
											{/* <div className="accordion-heading">
										<Typography variant="h3">{section.heading}</Typography>
									</div> */}
											<div className="all-accordions">
												{section.accordions.map((accordion, i) => (
													<Accordion
														key={i}
														title={accordion.title}
														content={accordion.content}
													/>
												))}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default LandingPage;