import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';
import PropTypes from 'prop-types';
import { Typography } from '../../Base';

const CustomSlider = ({ slides, centerMode = false }) => {
	const settings = {
		centerMode: centerMode,
		centerPadding: '100px',
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 2000,
		autoplaySpeed: 4000,
		cssEase: 'linear',
		arrows: false,
		focusOnSelect: false,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					centerPadding: '0px'
				}
			},
			{
				breakpoint: 768,
				settings: {
					centerPadding: '0px'
				}
			},
			{
				breakpoint: 992,
				settings: {
					centerPadding: '0px'
				}
			},
			{
				breakpoint: 1200,
				settings: {
					centerPadding: '0px'
				}
			}
		]
	};

	return (
		<Slider {...settings}>
			{slides.map((slide, index) => (
				<div key={index} className="custom-slide">
					<img src={slide.img} className="img-fluid p-2 p-md-5" alt="" />
					<div className="text-container">
						<Typography variant="h2" className="h2">
							{slide.title}
						</Typography>
						<Typography variant="h3" className="h3">
							{slide.text}
						</Typography>

						<Typography variant="body-1" className="initials">
							{slide.personName}
							<span>{slide.designation}</span>
						</Typography>
					</div>
				</div>
			))}
		</Slider>
	);
};

CustomSlider.propTypes = {
	slides: PropTypes.object.isRequired,
	centerMode: PropTypes.bool
};

export default CustomSlider;
