import React from 'react';
import { useParams } from 'react-router-dom';
import { Services } from './ServicesData';
import InnerPageBanner from '../InnerPageBanner';
import { GroupImage, RisingStars } from 'src/assets';


const ServiceDetailsDefault = () => {
  const { id } = useParams();
  const service = Services.find(service => service.id === parseInt(id));

  if (!service) {
    return <div>      <InnerPageBanner title={'Service Not Found'} />    </div>;
  }

  const backgroundImageUrl = GroupImage;
  // const pageTitle = 'About Us';
  const pageTitle = service.title

  return (
    <>
      {/* Banner Section */}
      <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />
      {/* Banner Section */}

      {/* Page Content Section */}
      <section className="services-sec pb-0">
        <div className="ah-container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="description mb-0 mb-lg-5">
                <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Services</span></div>
                <br />
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            </div>
            <div className='w-100'></div>
          </div>
        </div>
      </section>
      {/* Page Content Section */}
      <section className="twoSideFullWidthSec for-services-details bg-cover ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 sticky">
              <div className="inner-box left-inner-box bg-theme-light mb-0">
                <div>
                  <img className={`desktop animate__fadeInLeft animate__animated animate__slow`} style={{ width: '100%', height: 'auto' }} src={service.image} alt={service.title} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 non-sticky">
              <div className="inner-box mb-0">
                <div>
                  <div className='desktop animate__fadeInRight animate__animated animate__slow description'>
                    <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Services</span></div>
                    <br />
                    <h2>{service.serviceInnerSecTitle}</h2>
                    <p className='mb-5'>{service.serviceInnerSecDescription}</p>
                    {/* <h4>Question</h4>
                    <p className='ah-mb-50'>Answer</p> */}
                    <ol className='questions-list'>
                      {service.serviceDetailsSecArray.map((serviceDetailsSecArray, index) => (
                        <li key={index}>
                          <div className='questions-title'>{serviceDetailsSecArray.title}</div>
                          {/* <p>{serviceDetailsSecArray.description}</p> */}
                          <p dangerouslySetInnerHTML={{ __html: serviceDetailsSecArray.description }}></p>

                          {serviceDetailsSecArray.serviceDetailsSecArraySubDetails && (
                            <ul className='sub-ul'>
                              {serviceDetailsSecArray.serviceDetailsSecArraySubDetails.map((serviceDetailsSecArraySubDetails, childIndex) => (
                                <li key={childIndex}>
                                  <strong>{serviceDetailsSecArraySubDetails.question}</strong>
                                  <p dangerouslySetInnerHTML={{ __html: serviceDetailsSecArraySubDetails.answer }}></p>
                                </li>
                              ))
                              }
                            </ul>
                          )}
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetailsDefault;