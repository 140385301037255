import React from 'react';
// import { Link } from 'react-router-dom';
// import { BrandIcon1, BrandIcon2, BrandIcon3, BrandIcon4, Product, } from 'src/assets';
// import { Typography, Button } from 'src/components/common/Base';
import { SoftwareImg, PhoneCall, BusinessCard, healthCare} from 'src/assets';
import './style.scss';

const LaunchYouBusiness = () => {

    return (
        <>
            <section className="launchYourBusiness-sec bg-light">
                <div className="ah-container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6 col-md-12 mb-5 mb-lg-0">
                            <div className="img-box">
                                <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '100%', height: 'auto' }} src={SoftwareImg} alt="SoftwareImg" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="description mb-0">
                                <h2 className=''>To Launch Your Business With Us And Expand It</h2>
                                <ul className='business-flow'>
                                    <li>
                                        <figure>
                                            <div>
                                                <img className={`desktop animate__fadeInUp animate__animated animate__slow`} src={PhoneCall} alt="cameraImg" />
                                            </div>
                                            <figcaption>
                                                <h4>Keep up with our agency solutions.</h4>
                                                <p>Tristique nulla aliquet enim tortor at auctor. Lacus vestibulum sed arcu non odio.Et ultrices neque ornare aenean euismod elementum nisi cursus.</p>
                                            </figcaption>
                                        </figure>
                                    </li>
                                    <li>
                                        <figure>
                                            <div>
                                                <img className={`desktop animate__fadeInUp animate__animated animate__slow`} src={BusinessCard} alt="cameraImg" />
                                            </div>
                                            <figcaption>
                                                <h4>Building blocks for business success.</h4>
                                                <p>Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque. Mattis enim ut tellus elementum. Viverra nibh cras pulvinar mattis nunc sed ultricies.</p>
                                            </figcaption>
                                        </figure>
                                    </li>
                                    <li>
                                        <figure>
                                            <div>
                                                <img className={`desktop animate__fadeInUp animate__animated animate__slow`} src={healthCare} alt="cameraImg" />
                                            </div>
                                            <figcaption>
                                                <h4>Business potential with our cloud.</h4>
                                                <p>Congue quam ut, venenatis metus. Orci varius natoque penatibus eturm magnis dis parturient montes, nascetur ridicumus. Phasellus congue quis ligula.</p>
                                            </figcaption>
                                        </figure>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LaunchYouBusiness;