import React from 'react';
import { GroupImage } from 'src/assets';
import { InnerPageBanner, Login } from 'src/components/common/Widgets';
import './style.scss';
// import './libs.js';

const LoginDefault = () => {
    
    // Inner Banner
    const pageTitle = 'Login';
    const backgroundImageUrl = GroupImage;

    return (
        <>
            <div>
                <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle}/>
            </div>
            <div>
                <Login />
            </div>
        </>
    );
};

export default LoginDefault;
