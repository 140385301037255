import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { WhiteLogo } from 'src/assets';

const Footer = () => {
	const UsefulFooterLinks = [
		{ id: 1, title: 'Home', url: '/' },
		{ id: 2, title: 'About Us', url: '/about-us' },
		{ id: 3, title: 'Services', url: '/services' },
		{ id: 4, title: 'Contact Us', url: '/contact-us' },
	];

	const ServicesLinks = [
		{ id: 1, title: 'Web Development', url: '/Services/1' },
		{ id: 2, title: 'UI UX Designing', url: '/Services/2' },
		{ id: 3, title: 'SEO', url: '/Services/3' },
		{ id: 4, title: 'Graphic Design', url: '/Services/4' },
	];

	const CopyrightFooterLinks = [
		{ id: 1, title: 'Terms & Conditions', url: '/terms-conditions' },
		{ id: 2, title: 'Privacy Policy', url: '/privacy-policy' },
	];

	return (
		<div className='ah-footer-new-changes'>
			{/* <footer className="footer-section theme-bg" style={{ backgroundImage: `url(${ThemeBg})` }}> */}
			<footer className="footer-section" >
				<div className="container">
					<div className="footer-content pt-5 pb-5">
						<div className="row">
							<div className="col-xl-4 col-lg-4 mb-50">
								<div className="footer-widget me-md-5">
									<div className="footer-logo">
										<Link to="/">
											<img src={WhiteLogo} className="img-fluid" alt="logo" />
										</Link>
									</div>
									<div className="footer-text">
										<p>Our journey began in 2016 with a team of seven visionaries. Since then, we have transformed into a comprehensive mobile-centric software development company.</p>
									</div>
									<div className="footer-social-icon">
										<span>Follow us</span>
										<Link to="https://www.facebook.com/people/Genius-System-Logix/61555030210321/" target="_blank">
											<i className="fab fa-facebook facebook-bg"></i>
										</Link>
										{/* <Link to="https://www.twitter.com/" target="_blank">
											<i className="fab fa-twitter twitter-bg"></i>
										</Link> */}
										<Link to="https://www.linkedin.com/company/genius-system-logix?trk=public_post_feed-actor-name" target="_blank">
											<i className="fab fa-linkedin facebook-bg"></i>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-30">
								<div className="footer-widget">
									<div className="footer-widget-heading">
										<h3 className="text-white">Quick Links</h3>
									</div>
									<ul>
										{UsefulFooterLinks.map(link => (
											<li key={link.id}>
												<Link className="subListHeading" to={link.url}>{link.title}</Link>
											</li>
										))}
									</ul>
								</div>
							</div>
							<div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-30">
								<div className="footer-widget">
									<div className="footer-widget-heading">
										<h3 className="text-white">Services</h3>
									</div>
									<ul>
										{ServicesLinks.map(link => (
											<li key={link.id}>
												<Link className="subListHeading" to={link.url}>{link.title}</Link>
											</li>
										))}
									</ul>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4  mb-50">
								<div className="footer-widget">
									<div className="footer-widget-heading">
										<h3 className="text-white">Subscribe</h3>
									</div>
									<div className="footer-text mb-25">
										<p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
									</div>
									<div className="subscribe-form">
										<form action="#" id='subscription-form'>
											<input type="email" placeholder="Email Address" id="subscription-input" name="subscription-input" required/>
											<button aria-label="Send"><i className="fab fa-telegram-plane"></i></button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copyright-area">
					<div className="container">
						<div className="row">
							<div className="col-xl-6 col-lg-6 text-start text-lg-left">
								<div className="copyright-text">
									<p>Copyright &copy; 2024, All Rights Reserved <Link className="subListHeading" to="/">Genius System Logix</Link></p>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 d-none d-lg-block">
								<div className="footer-menu">
									<ul className="text-end">
										{CopyrightFooterLinks.map(link => (
											<li key={link.id}>
												<Link className="subListHeading" to={link.url}>{link.title}</Link>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Footer;