import { Outlet } from 'react-router-dom';

const Default = () => {
	return (
		<main>
			<Outlet />
		</main>
	);
};

export default Default;
