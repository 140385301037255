import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RisingStars, DeleteIcon } from 'src/assets';
import './style.scss';

const CareerPostList = () => {
  const [jobs, setJobs] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  let userId = localStorage.getItem("loggedInUserId");

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await fetch('http://localhost:3002/api/jobs/getAllJobs');
      const data = await response.json();
      setJobs(data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const handleDeleteConfirmation = (id) => {
    setJobToDelete(id);
    setDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    try {
      if (jobToDelete) {
        // Handle job deletion
      } else {
        console.error('No job to delete');
      }
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  return (
    <section className="services-sec">
      <div className="ah-container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="description">
              <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Careers</span></div>
              <br />
              <h2>We Are <span>Hiring</span></h2>
              <p>Genius System Logix provides the comprehensive digital suite of products and services as per clients expectations, summary of range of products is numerated in ensuing headings.</p>
            </div>
          </div>
          <div className='w-100'></div>
          {jobs.length === 0 ? (
            <div className="col-md-12 text-center">
              <p className='text-danger'>There are no jobs available right now.</p>
            </div>
          ) : (
            jobs.map(job => (
              <div key={job.Id} className='col-md-6 p-relative'>
                <Link className="d-link" to={`/career/${job.Id}`}>
                  <div className='job-box'>
                    <div className='short-description'>
                      <h3 className=''>{job.title}</h3>
                      <div className='info'><span>Remote</span> - <span>Pakistan/Karachi</span> - <span>Full Time</span></div>
                    </div>
                    <div className="icon-container">
                      <span className='icon'>&#10148;</span>
                    </div>
                  </div>
                </Link>
                {(userId == job.userId) && (
                  <button onClick={() => handleDeleteConfirmation(job.Id)} className='remove-icon'>
                    <img src={DeleteIcon} alt="Remove Icon" />
                  </button>
                )}
              </div>
            ))
          )}
          {deleteConfirmation && (
            <div className="confirmation-modal">
              <div>
                <p>Are you sure you want to delete this job?</p>
                <button onClick={handleDelete}>Yes</button>
                <button onClick={() => setDeleteConfirmation(false)}>No</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CareerPostList;