import './style.scss';
// servicesData.js
import {
  WebDesignDevelopment, 
  UIUXDesigning, 
  BusinessConsulting, 
  SearchEngineOptimization, 
  GraphicDesign, 
  // SplitSlider1, 
  VideoAnimation, 
  DataScience,
  // seo, 
  // WebDesign, 
  // UiUxDesign, 
  seoService,
  WebDesignService,
  UiUxDesignService,
  GraphicDesignService,
  DataScienceService,
  VideoImageService
} from 'src/assets';

export const Services = [
  {
    id: 1,
    icon: WebDesignDevelopment,
    title: 'Web Design & Development',
    description: 'At Genius System Logix, our skilled team of web designers and developers excels in crafting dynamic and innovative digital solutions. Through their dedication, we have achieved excellence in delivering top-notch web design and development services to global brands.',
    bgImage: WebDesignDevelopment,
    image: WebDesignService,
    serviceInnerSecTitle: "Web Design:",
    serviceInnerSecDescription: "Boost your online presence with our web development company. We're experts in creating unique websites using the latest technologies, helping businesses achieve their goals smoothly.",
    serviceDetailsSecArray: [
      {
        title: 'Web development company:',
        description: "Boost your online presence with our web development company. We're experts in creating unique websites using the latest technologies, helping businesses achieve their goals smoothly. Our skilled team will work closely with you to build a stunning custom website that exceeds your expectations.",

      },
      {
        title: 'Premier web design and development solutions',
        description: "If you're considering a web development project, you've landed in the perfect spot. As a web development firm with a proven track record in delivering cutting-edge web solutions, we offer expert web services to ambitious companies. Our talented web developers are ready to deliver a high-caliber product customized to your business's unique requirements. Keeping abreast of the latest trends, our entire team ensures your new web application stands out from the crowd and delivers real value to your customers.",
      },
      {
        title: "Qualities that make us unique",
        description: "Attaining a competitive edge demands a product that's technologically superior, exceptionally functional, and visually compelling. Our web development company is dedicated to meeting these criteria, offering guidance throughout the development journey. Explore what makes us the ideal partner for delivering your web solution.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Experienced Team Members",
            answer: "Our web development squad comprises over 10 React.js specialists, most of whom are seasoned website developers with extensive experience in crafting web solutions that meet user needs.",
          },
          {
            question: "Streamlined Development Process",
            answer: "We meticulously scrutinized every aspect of our web development process to pinpoint tasks ripe for automation, thereby establishing a framework to tackle more complex ones. Adhering to predefined procedures enables us to construct top-notch web applications within designated timelines and budgets.",
          },
          {
            question: "Extensive Industry Insight",
            answer: "Partner with a company versed in diverse sectors, including Blockchain, iGaming, FinTech, and Crypto. By merging business acumen with web development expertise, we can design an industry-tailored solution aligned with your company's objectives.",
          },
          {
            question: "Serving Established and Emerging Businesses",
            answer: "With a history of collaborating with a wide array of organizations, from startups to enterprises, we're well-equipped to assist you in achieving your goals, regardless of your company's size.",
          }
        ]
      },
      {
        title: "Exceptional web applications crafted with cutting-edge tools & technologies",
        description: "At Genius System Logix, we leverage only premium web development tools and technologies to deliver unparalleled User Experience for your customers. Let's collaborate to create a highly responsive and captivating web application.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "JavaScript",
            answer: "JavaScript serves as the backbone of web development for several reasons, including accelerated development pace, seamless integration with other technologies, and broad accessibility. We harness these advantages to develop interactive web applications with dynamic content, animations, and advanced features, ensuring engaging user experiences. Thanks to rapid rendering and real-time updates, JavaScript-coded web apps run smoothly without glitches.",
          },
          {
            question: "TypeScript",
            answer: "TypeScript, an extension of JavaScript, enhances developers' productivity and expedites development by improving code structure and early problem detection. When combined with JavaScript, TypeScript enables the creation of sophisticated web apps in a shorter timeframe, reducing Time to Market.",
          },
          {
            question: "React",
            answer: "Built on the principle of 'learn once, write anywhere,' React empowers us to enhance products without rewriting code, regardless of the platform. We utilize this versatile technology to develop complex and highly responsive web and mobile UIs, enhancing customer engagement and retention. Additionally, React supports server-side rendering, enhancing performance and SEO for web-based products.",
          },
          {
            question: "Next.js",
            answer: "Next.js, a member of the JavaScript family, facilitates rapid deployment by integrating top tools. Continuously updated, Next.js allows us to develop full-stack web applications faster than other frameworks. Our developers leverage Next.js to create intricate interfaces with clear yet impressive layouts. Moreover, automatic code splitting reduces initial load times, minimizing user abandonment due to sluggish performance. Supporting TypeScript and commonly used HTML, Next.js enables us to deliver optimal User Experience by blending all three technologies seamlessly.",
          }
        ]
      },
      {
        title: "Web Development Process Simplified",
        description: "At Genius System Logix, each project adheres to a well-defined framework, ensuring the delivery of top-notch web apps within a reasonable timeframe. We advocate for your involvement at every stage, as we believe close collaboration yields superior results. Working with us means gaining a genuine partner and advisor who will guide you through the web development process, outlined below:",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Product Discovery Workshops",
            answer: "Our journey begins with comprehensive Product Discovery Workshops, where we delve into your app concept to assess its viability. By gaining a thorough understanding of your business objectives, we craft a precise implementation plan and project roadmap, outlining milestones and deadlines for our development team to follow.",
          },
          {
            question: "UX/UI Design",
            answer: "Next, we meticulously design your product to ensure a visually appealing and intuitive User Experience. Through market research and user persona creation, we generate simple wireframes that evolve into interactive prototypes, culminating in an official draft of your web app that aligns with your brand identity.",
          },
          {
            question: "Agile Development & QA",
            answer: "In the development phase, we transform designs into a fully functional app. Following the Scrum methodology, our team delivers app components in cycles and conducts rigorous automated testing to ensure efficiency. Supervised by an experienced Scrum Master, this stage ensures the smooth delivery of your product.",
          },
          {
            question: "Release",
            answer: "Upon completion of development and testing, we prepare your web application for launch. After configuring the server and conducting thorough testing, we ensure the application can handle traffic before making it available to your users.",
          },
          {
            question: "Maintenance",
            answer: "Our commitment to your project doesn't end with the launch. As a full-service web development agency, we provide ongoing maintenance, including bug fixes, security updates, and technology upgrades, ensuring your app remains at its best. Ready to experience it firsthand? Partner with us and see the difference.",
          },
        ]
      },
      {
        title: "Not Quite Ready for App Development?",
        description: "Our Product Design Workshops offer a solution to clarify your vision and establish project documentation. Alternatively, we can organize a Product Discovery session to assess your app concept and strategize the project from inception."
      },
      {
        title: "Tech Stack for All Web Development Needs",
        description: "Whether you're enhancing your existing app or embarking on a new project, we've got you covered. Our team will guide you in selecting the most suitable technologies and tools to create a product tailored to your needs, choosing from the following options:",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Programming Languages",
            answer: "Java, Python, JavaScript, TypeScript",
          },
          {
            question: "Databases",
            answer: "MongoDB, Redis, Indexed DB",
          },
          {
            question: "Frameworks",
            answer: "React, Next.js, Nest.js, Node.js, Spring, Hibernate, Django, Redux Toolkit (RTK Query), Jest, Cypress",
          },
          {
            question: "Architecture",
            answer: "React Context / Redux, Saga, Server-Side Rendering (SSR), Single Page Application (SPA), Micro Frontends, Atomic Design.",
          },
        ]
      },
    ]
  },
  {
    id: 2,
    icon: UIUXDesigning,
    title: 'UI UX Designing',
    description: 'We believe in a complete approach to UX/UI design that goes beyond just looks. We prioritize user-friendly design principles and blend creativity with your business goals.',
    bgImage: BusinessConsulting,
    image: UiUxDesignService,
    serviceInnerSecTitle: "Mobile App Development:",
    serviceInnerSecDescription: "Boost your online presence with our web development company. We're experts in creating unique websites using the latest technologies, helping businesses achieve their goals smoothly.",
    serviceDetailsSecArray: [
      {
        title: 'Our Complete UX/UI Design Process',
        description: "We believe in a complete approach to UX/UI design that goes beyond just looks. We prioritize user-friendly design principles and blend creativity with your business goals. Every step of our design process is carefully planned to not only improve the appearance but also to help you succeed and connect with your target users. From the initial sketches and prototypes to the polished final designs, our goal is to maintain the highest level of quality. We make sure that your users can easily navigate and interact with the digital world you've created.",

      },
      {
        title: 'Investigate',
        // description: "",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Workshops",
          },
          {
            question: "Business requirements",
          },
          {
            question: "Customer journey Maps",
          },
          {
            question: "Personas",
          },
          {
            question: "Card sorting",
          },
          {
            question: "Focus groups",
          },
        ]
      },
      {
        title: 'Design',
        // description: "",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Workshops",
          },
          {
            question: "Information Architecture",
          },
          {
            question: "Wireframes",
          },
          {
            question: "Prototypes",
          },
          {
            question: "Visual design",
          },
          {
            question: "Data visualization",
          },
        ]
      },
      {
        title: 'Evaluate',
        // description: "",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Usability testing",
          },
          {
            question: "Emotional response testing",
          },
          {
            question: "Focus groups",
          },
          {
            question: "Accessibility evaluation",
          },
        ]
      },
      {
        title: "Exceptional web applications crafted with cutting-edge tools & technologies",
        description: "At Genius System Logix, we leverage only premium web development tools and technologies to deliver unparalleled User Experience for your customers. Let's collaborate to create a highly responsive and captivating web application.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "JavaScript",
            answer: "JavaScript serves as the backbone of web development for several reasons, including accelerated development pace, seamless integration with other technologies, and broad accessibility. We harness these advantages to develop interactive web applications with dynamic content, animations, and advanced features, ensuring engaging user experiences. Thanks to rapid rendering and real-time updates, JavaScript-coded web apps run smoothly without glitches.",
          },
          {
            question: "TypeScript",
            answer: "TypeScript, an extension of JavaScript, enhances developers' productivity and expedites development by improving code structure and early problem detection. When combined with JavaScript, TypeScript enables the creation of sophisticated web apps in a shorter timeframe, reducing Time to Market.",
          },
          {
            question: "React",
            answer: "Built on the principle of 'learn once, write anywhere,' React empowers us to enhance products without rewriting code, regardless of the platform. We utilize this versatile technology to develop complex and highly responsive web and mobile UIs, enhancing customer engagement and retention. Additionally, React supports server-side rendering, enhancing performance and SEO for web-based products.",
          },
          {
            question: "Next.js",
            answer: "Next.js, a member of the JavaScript family, facilitates rapid deployment by integrating top tools. Continuously updated, Next.js allows us to develop full-stack web applications faster than other frameworks. Our developers leverage Next.js to create intricate interfaces with clear yet impressive layouts. Moreover, automatic code splitting reduces initial load times, minimizing user abandonment due to sluggish performance. Supporting TypeScript and commonly used HTML, Next.js enables us to deliver optimal User Experience by blending all three technologies seamlessly.",
          }
        ]
      },
      {
        title: "Web Development Process Simplified",
        description: "At Genius System Logix, each project adheres to a well-defined framework, ensuring the delivery of top-notch web apps within a reasonable timeframe. We advocate for your involvement at every stage, as we believe close collaboration yields superior results. Working with us means gaining a genuine partner and advisor who will guide you through the web development process, outlined below:",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Product Discovery Workshops",
            answer: "Our journey begins with comprehensive Product Discovery Workshops, where we delve into your app concept to assess its viability. By gaining a thorough understanding of your business objectives, we craft a precise implementation plan and project roadmap, outlining milestones and deadlines for our development team to follow.",
          },
          {
            question: "UX/UI Design",
            answer: "Next, we meticulously design your product to ensure a visually appealing and intuitive User Experience. Through market research and user persona creation, we generate simple wireframes that evolve into interactive prototypes, culminating in an official draft of your web app that aligns with your brand identity.",
          },
          {
            question: "Agile Development & QA",
            answer: "In the development phase, we transform designs into a fully functional app. Following the Scrum methodology, our team delivers app components in cycles and conducts rigorous automated testing to ensure efficiency. Supervised by an experienced Scrum Master, this stage ensures the smooth delivery of your product.",
          },
          {
            question: "Release",
            answer: "Upon completion of development and testing, we prepare your web application for launch. After configuring the server and conducting thorough testing, we ensure the application can handle traffic before making it available to your users.",
          },
          {
            question: "Maintenance",
            answer: "Our commitment to your project doesn't end with the launch. As a full-service web development agency, we provide ongoing maintenance, including bug fixes, security updates, and technology upgrades, ensuring your app remains at its best. Ready to experience it firsthand? Partner with us and see the difference.",
          },
        ]
      },
      {
        title: "Not Quite Ready for App Development?",
        description: "Our Product Design Workshops offer a solution to clarify your vision and establish project documentation. Alternatively, we can organize a Product Discovery session to assess your app concept and strategize the project from inception."
      },
      {
        title: "Tech Stack for All Web Development Needs",
        description: "Whether you're enhancing your existing app or embarking on a new project, we've got you covered. Our team will guide you in selecting the most suitable technologies and tools to create a product tailored to your needs, choosing from the following options:",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Programming Languages",
            answer: "Java, Python, JavaScript, TypeScript",
          },
          {
            question: "Databases",
            answer: "MongoDB, Redis, Indexed DB",
          },
          {
            question: "Frameworks",
            answer: "React, Next.js, Nest.js, Node.js, Spring, Hibernate, Django, Redux Toolkit (RTK Query), Jest, Cypress",
          },
          {
            question: "Architecture",
            answer: "React Context / Redux, Saga, Server-Side Rendering (SSR), Single Page Application (SPA), Micro Frontends, Atomic Design.",
          },
        ]
      },
    ]
  },
  {
    id: 3,
    icon: SearchEngineOptimization,
    title: 'Search Engine Optimization',
    description: 'Genius System Logix, your trusted partner for effective Search Engine Optimization (SEO) solutions!',
    bgImage: BusinessConsulting,
    image: seoService,
    serviceInnerSecTitle: "SEO:",
    serviceInnerSecDescription: "Genius System Logix, your trusted partner for effective Search Engine Optimization (SEO) solutions!",
    serviceDetailsSecArray: [
      {
        // title: 'Web development company Test',
        description: "Maximize Your Online Visibility with Expert SEO Strategies",

      },
      {
        description: "In today's digital landscape, appearing on the first page of search engine results is crucial for driving organic traffic to your website and growing your online presence. At Genius System Logix, we specialize in crafting tailored SEO strategies that elevate your website's ranking and ensure maximum visibility to your target audience.",
      },
      {
        description: "Our team of SEO experts stays ahead of the curve with the latest industry trends and algorithm updates to deliver results-driven solutions that propel your business to success. Whether you're a small local business or a large enterprise, we have the expertise and experience to help you achieve your SEO goals.",
      },
      {
        description: "Customized SEO Solutions for Your Business Needs",
      },
      {
        description: "We understand that every business is unique, which is why we offer personalized SEO solutions tailored to your specific needs and objectives. From keyword research to on-page optimization and link building, we develop a comprehensive SEO strategy that aligns with your brand identity and business goals.",
      },
      {
        title: "Our Services",
        description: "Keyword Research: Identify high-value keywords relevant to your industry and target audience to drive qualified traffic to your website.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "On-Page Optimization:",
            answer: "Optimize your website's content, meta tags, and internal linking structure to improve search engine visibility and user experience.",
          },
          {
            question: "Off-Page Optimization:",
            answer: "Build high-quality backlinks from authoritative websites to boost your website's credibility and authority in the eyes of search engines.",
          },
          {
            question: "Content Creation: ",
            answer: " Develop engaging and informative content that resonates with your audience and attracts natural inbound links.",
          },
          {
            question: "Local SEO: ",
            answer: "Enhance your online presence in local search results and attract customers in your area with targeted local SEO strategies.",
          },
        ]
      },
      {
        title: "Why Choose Us?",
        description: "Keyword Research: Identify high-value keywords relevant to your industry and target audience to drive qualified traffic to your website.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Proven Track Record:",
            answer: "We have a track record of delivering tangible results for our clients, helping them achieve higher rankings and increased organic traffic.",
          },
          {
            question: "Transparent Reporting:",
            answer: "We provide detailed monthly reports that track the performance of your SEO campaigns, so you can see the progress and measure the impact of our efforts.",
          },
          {
            question: "Customized Approach:",
            answer: "SEO is an ongoing process, and we continuously monitor and optimize your campaigns to ensure long-term success.",
          }
        ]
      },
      {
        description: "Ready to take your online presence to the next level with expert SEO services? Contact us today to schedule a consultation and let's discuss how we can help you achieve your business objectives!",
      },
    ]
  },
  {
    id: 4,
    icon: GraphicDesign,
    title: 'Graphic Design',
    description: 'With mastery in design, our graphic artists are deeply versed in the corporate realm, showcasing their expertise in the enduring impact of effective design on branding.',
    bgImage: BusinessConsulting,
    image: GraphicDesignService,
    serviceInnerSecTitle: "Graphic Design",
    serviceInnerSecDescription: "With mastery in design, our graphic artists are deeply versed in the corporate realm, showcasing their expertise in the enduring impact of effective design on branding.",
    serviceDetailsSecArray: [
      {
        title: 'Our dedicated team of design professionals is committed to portraying the essence of evolving brands with precision.',
        description: "</br>With mastery in design, our graphic artists are deeply versed in the corporate realm, showcasing their expertise in the enduring impact of effective design on branding.</br></br>As a premier visual design agency, our overarching objective is to set your business apart from the competition. Businesses of all sizes rely on our graphic design offerings. We provide comprehensive yet cost-effective solutions, delivering tangible value and return on investment. Whether you're a start-up, an individual, or an established enterprise, we offer personalized attention and strive for perfection in every project, from crafting a simple logo to a complete corporate identity overhaul.</br></br>Our dedicated team of design professionals is committed to portraying the essence of evolving brands with precision.</br></br>With mastery in design, our graphic artists are deeply versed in the corporate realm, showcasing their expertise in the enduring impact of effective design on branding.</br></br>As a premier visual design agency, our overarching objective is to set your business apart from the competition. Businesses of all sizes rely on our graphic design offerings. We provide comprehensive yet cost-effective solutions, delivering tangible value and return on investment. Whether you're a start-up, an individual, or an established enterprise, we offer personalized attention and strive for perfection in every project, from crafting a simple logo to a complete corporate identity overhaul.",
      },
      {
        title: "Genius System Logix, where creativity meets innovation in graphic design!",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Captivate Your Audience with Stunning Visuals",
            answer: "In today's digital age, a captivating visual presence is essential for any business to stand out. At [Your Company Name], we specialize in creating eye-catching graphic designs that leave a lasting impression on your audience.</br></br>Our team of experienced graphic designers is dedicated to bringing your brand to life through creative and compelling visuals. Whether you need a new logo, website graphics, social media posts, or marketing materials, we have the expertise to make your vision a reality.",
          },
          {
            question: "Tailored Solutions for Your Unique Needs",
            answer: "We understand that every business is different, which is why we offer customized graphic design solutions tailored to your specific needs. From concept development to final execution, we work closely with you to ensure that our designs align with your brand identity and messaging.",
          }
        ]
      }, {
        title: "Our Services",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Logo Design:",
            answer: "Make a memorable first impression with a professionally designed logo that reflects your brand's personality and values.",
          }, {
            question: "Branding & Identity:",
            answer: "Establish a cohesive brand identity across all your marketing materials with our comprehensive branding services.",
          }, {
            question: "Web Graphics:",
            answer: "Enhance your website's visual appeal with custom graphics and illustrations that engage your visitors and keep them coming back for more.",
          }, {
            question: "Social Media Graphics:",
            answer: "Stand out on social media platforms with eye-catching graphics that grab attention and drive engagement.",
          }, {
            question: "Marketing Materials:",
            answer: "From business cards to brochures, we create impactful marketing materials that help you effectively promote your products or services.",
          }
        ]
      }, {
        title: "Why Choose Us?",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Creative Excellence",
            answer: "Our team is passionate about pushing the boundaries of creativity to deliver designs that exceed your expectations.",
          }, {
            question: "Timely Delivery",
            answer: "We understand the importance of deadlines and strive to deliver high-quality designs within the agreed-upon timeframe.",
          }, {
            question: "Client Satisfaction",
            answer: "Your satisfaction is our top priority, and we go above and beyond to ensure that you are happy with the result. </br></br>Ready to elevate your brand with stunning graphic design? Contact us today to discuss your project requirements and let's bring your vision to life!",
          }
        ]
      },
    ]
  },
  {
    id: 5,
    icon: VideoAnimation,
    title: 'Video Animation',
    description: 'Genius System Logix team of video animators breathe life into static images, turning them into vibrant and captivating visuals. Our repertoire includes Whiteboard Animations, 2D Explainer, and Graphic Videos, all proven to be highly effective in promoting and selling your products.',
    bgImage: BusinessConsulting,
    image: VideoImageService,
    serviceInnerSecTitle: "Video Animation:",
    serviceInnerSecDescription: "Boost your online presence with our web development company. We're experts in creating unique websites using the latest technologies, helping businesses achieve their goals smoothly.",
    serviceDetailsSecArray: [
      {
        title: 'Web development company Test',
        description: "Boost your online presence with our web development company. We're experts in creating unique websites using the latest technologies, helping businesses achieve their goals smoothly. Our skilled team will work closely with you to build a stunning custom website that exceeds your expectations.",

      },
      {
        title: 'Premier web design and development solutions',
        description: "If you're considering a web development project, you've landed in the perfect spot. As a web development firm with a proven track record in delivering cutting-edge web solutions, we offer expert web services to ambitious companies. Our talented web developers are ready to deliver a high-caliber product customized to your business's unique requirements. Keeping abreast of the latest trends, our entire team ensures your new web application stands out from the crowd and delivers real value to your customers.",
      },
      {
        title: "Qualities that make us unique",
        description: "Attaining a competitive edge demands a product that's technologically superior, exceptionally functional, and visually compelling. Our web development company is dedicated to meeting these criteria, offering guidance throughout the development journey. Explore what makes us the ideal partner for delivering your web solution.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Experienced Team Members",
            answer: "Our web development squad comprises over 10 React.js specialists, most of whom are seasoned website developers with extensive experience in crafting web solutions that meet user needs.",
          },
          {
            question: "Streamlined Development Process",
            answer: "We meticulously scrutinized every aspect of our web development process to pinpoint tasks ripe for automation, thereby establishing a framework to tackle more complex ones. Adhering to predefined procedures enables us to construct top-notch web applications within designated timelines and budgets.",
          },
          {
            question: "Extensive Industry Insight",
            answer: "Partner with a company versed in diverse sectors, including Blockchain, iGaming, FinTech, and Crypto. By merging business acumen with web development expertise, we can design an industry-tailored solution aligned with your company's objectives.",
          },
          {
            question: "Serving Established and Emerging Businesses",
            answer: "With a history of collaborating with a wide array of organizations, from startups to enterprises, we're well-equipped to assist you in achieving your goals, regardless of your company's size.",
          }
        ]
      },
      {
        title: "Exceptional web applications crafted with cutting-edge tools & technologies",
        description: "At Genius System Logix, we leverage only premium web development tools and technologies to deliver unparalleled User Experience for your customers. Let's collaborate to create a highly responsive and captivating web application.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "JavaScript",
            answer: "JavaScript serves as the backbone of web development for several reasons, including accelerated development pace, seamless integration with other technologies, and broad accessibility. We harness these advantages to develop interactive web applications with dynamic content, animations, and advanced features, ensuring engaging user experiences. Thanks to rapid rendering and real-time updates, JavaScript-coded web apps run smoothly without glitches.",
          },
          {
            question: "TypeScript",
            answer: "TypeScript, an extension of JavaScript, enhances developers' productivity and expedites development by improving code structure and early problem detection. When combined with JavaScript, TypeScript enables the creation of sophisticated web apps in a shorter timeframe, reducing Time to Market.",
          },
          {
            question: "React",
            answer: "Built on the principle of 'learn once, write anywhere,' React empowers us to enhance products without rewriting code, regardless of the platform. We utilize this versatile technology to develop complex and highly responsive web and mobile UIs, enhancing customer engagement and retention. Additionally, React supports server-side rendering, enhancing performance and SEO for web-based products.",
          },
          {
            question: "Next.js",
            answer: "Next.js, a member of the JavaScript family, facilitates rapid deployment by integrating top tools. Continuously updated, Next.js allows us to develop full-stack web applications faster than other frameworks. Our developers leverage Next.js to create intricate interfaces with clear yet impressive layouts. Moreover, automatic code splitting reduces initial load times, minimizing user abandonment due to sluggish performance. Supporting TypeScript and commonly used HTML, Next.js enables us to deliver optimal User Experience by blending all three technologies seamlessly.",
          }
        ]
      },
      {
        title: "Web Development Process Simplified",
        description: "At Genius System Logix, each project adheres to a well-defined framework, ensuring the delivery of top-notch web apps within a reasonable timeframe. We advocate for your involvement at every stage, as we believe close collaboration yields superior results. Working with us means gaining a genuine partner and advisor who will guide you through the web development process, outlined below:",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Product Discovery Workshops",
            answer: "Our journey begins with comprehensive Product Discovery Workshops, where we delve into your app concept to assess its viability. By gaining a thorough understanding of your business objectives, we craft a precise implementation plan and project roadmap, outlining milestones and deadlines for our development team to follow.",
          },
          {
            question: "UX/UI Design",
            answer: "Next, we meticulously design your product to ensure a visually appealing and intuitive User Experience. Through market research and user persona creation, we generate simple wireframes that evolve into interactive prototypes, culminating in an official draft of your web app that aligns with your brand identity.",
          },
          {
            question: "Agile Development & QA",
            answer: "In the development phase, we transform designs into a fully functional app. Following the Scrum methodology, our team delivers app components in cycles and conducts rigorous automated testing to ensure efficiency. Supervised by an experienced Scrum Master, this stage ensures the smooth delivery of your product.",
          },
          {
            question: "Release",
            answer: "Upon completion of development and testing, we prepare your web application for launch. After configuring the server and conducting thorough testing, we ensure the application can handle traffic before making it available to your users.",
          },
          {
            question: "Maintenance",
            answer: "Our commitment to your project doesn't end with the launch. As a full-service web development agency, we provide ongoing maintenance, including bug fixes, security updates, and technology upgrades, ensuring your app remains at its best. Ready to experience it firsthand? Partner with us and see the difference.",
          },
        ]
      },
      {
        title: "Not Quite Ready for App Development?",
        description: "Our Product Design Workshops offer a solution to clarify your vision and establish project documentation. Alternatively, we can organize a Product Discovery session to assess your app concept and strategize the project from inception."
      },
      {
        title: "Tech Stack for All Web Development Needs",
        description: "Whether you're enhancing your existing app or embarking on a new project, we've got you covered. Our team will guide you in selecting the most suitable technologies and tools to create a product tailored to your needs, choosing from the following options:",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Programming Languages",
            answer: "Java, Python, JavaScript, TypeScript",
          },
          {
            question: "Databases",
            answer: "MongoDB, Redis, Indexed DB",
          },
          {
            question: "Frameworks",
            answer: "React, Next.js, Nest.js, Node.js, Spring, Hibernate, Django, Redux Toolkit (RTK Query), Jest, Cypress",
          },
          {
            question: "Architecture",
            answer: "React Context / Redux, Saga, Server-Side Rendering (SSR), Single Page Application (SPA), Micro Frontend, Atomic Design.",
          },
        ]
      },
    ]
  },
  {
    id: 6,
    icon: DataScience,
    title: 'Data Science',
    description: 'We collaborate with organizations, leveraging freely available data combined with internal information to provide actionable insights. Our cost-effective platform analyzes, aggregates, and organizes vast amounts of data from various sources. We deliver insights through reports, dashboards, APIs, and visuals. Clients use our data services to enhance competitive understanding, brand monitoring, process optimization, and customized offerings, among other benefits.',
    bgImage: BusinessConsulting,
    image: DataScienceService,
    serviceInnerSecTitle: "Data Science:",
    serviceInnerSecDescription: "Boost your online presence with our web development company. We're experts in creating unique websites using the latest technologies, helping businesses achieve their goals smoothly.",
    serviceDetailsSecArray: [
      {
        title: 'Web development company Test',
        description: "Boost your online presence with our web development company. We're experts in creating unique websites using the latest technologies, helping businesses achieve their goals smoothly. Our skilled team will work closely with you to build a stunning custom website that exceeds your expectations.",

      },
      {
        title: 'Premier web design and development solutions',
        description: "If you're considering a web development project, you've landed in the perfect spot. As a web development firm with a proven track record in delivering cutting-edge web solutions, we offer expert web services to ambitious companies. Our talented web developers are ready to deliver a high-caliber product customized to your business's unique requirements. Keeping abreast of the latest trends, our entire team ensures your new web application stands out from the crowd and delivers real value to your customers.",
      },
      {
        title: "Qualities that make us unique",
        description: "Attaining a competitive edge demands a product that's technologically superior, exceptionally functional, and visually compelling. Our web development company is dedicated to meeting these criteria, offering guidance throughout the development journey. Explore what makes us the ideal partner for delivering your web solution.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Experienced Team Members",
            answer: "Our web development squad comprises over 10 React.js specialists, most of whom are seasoned website developers with extensive experience in crafting web solutions that meet user needs.",
          },
          {
            question: "Streamlined Development Process",
            answer: "We meticulously scrutinized every aspect of our web development process to pinpoint tasks ripe for automation, thereby establishing a framework to tackle more complex ones. Adhering to predefined procedures enables us to construct top-notch web applications within designated timelines and budgets.",
          },
          {
            question: "Extensive Industry Insight",
            answer: "Partner with a company versed in diverse sectors, including Blockchain, iGaming, FinTech, and Crypto. By merging business acumen with web development expertise, we can design an industry-tailored solution aligned with your company's objectives.",
          },
          {
            question: "Serving Established and Emerging Businesses",
            answer: "With a history of collaborating with a wide array of organizations, from startups to enterprises, we're well-equipped to assist you in achieving your goals, regardless of your company's size.",
          }
        ]
      },
      {
        title: "Exceptional web applications crafted with cutting-edge tools & technologies",
        description: "At Genius System Logix, we leverage only premium web development tools and technologies to deliver unparalleled User Experience for your customers. Let's collaborate to create a highly responsive and captivating web application.",
        serviceDetailsSecArraySubDetails: [
          {
            question: "JavaScript",
            answer: "JavaScript serves as the backbone of web development for several reasons, including accelerated development pace, seamless integration with other technologies, and broad accessibility. We harness these advantages to develop interactive web applications with dynamic content, animations, and advanced features, ensuring engaging user experiences. Thanks to rapid rendering and real-time updates, JavaScript-coded web apps run smoothly without glitches.",
          },
          {
            question: "TypeScript",
            answer: "TypeScript, an extension of JavaScript, enhances developers' productivity and expedites development by improving code structure and early problem detection. When combined with JavaScript, TypeScript enables the creation of sophisticated web apps in a shorter timeframe, reducing Time to Market.",
          },
          {
            question: "React",
            answer: "Built on the principle of 'learn once, write anywhere,' React empowers us to enhance products without rewriting code, regardless of the platform. We utilize this versatile technology to develop complex and highly responsive web and mobile UIs, enhancing customer engagement and retention. Additionally, React supports server-side rendering, enhancing performance and SEO for web-based products.",
          },
          {
            question: "Next.js",
            answer: "Next.js, a member of the JavaScript family, facilitates rapid deployment by integrating top tools. Continuously updated, Next.js allows us to develop full-stack web applications faster than other frameworks. Our developers leverage Next.js to create intricate interfaces with clear yet impressive layouts. Moreover, automatic code splitting reduces initial load times, minimizing user abandonment due to sluggish performance. Supporting TypeScript and commonly used HTML, Next.js enables us to deliver optimal User Experience by blending all three technologies seamlessly.",
          }
        ]
      },
      {
        title: "Web Development Process Simplified",
        description: "At Genius System Logix, each project adheres to a well-defined framework, ensuring the delivery of top-notch web apps within a reasonable timeframe. We advocate for your involvement at every stage, as we believe close collaboration yields superior results. Working with us means gaining a genuine partner and advisor who will guide you through the web development process, outlined below:",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Product Discovery Workshops",
            answer: "Our journey begins with comprehensive Product Discovery Workshops, where we delve into your app concept to assess its viability. By gaining a thorough understanding of your business objectives, we craft a precise implementation plan and project roadmap, outlining milestones and deadlines for our development team to follow.",
          },
          {
            question: "UX/UI Design",
            answer: "Next, we meticulously design your product to ensure a visually appealing and intuitive User Experience. Through market research and user persona creation, we generate simple wireframes that evolve into interactive prototypes, culminating in an official draft of your web app that aligns with your brand identity.",
          },
          {
            question: "Agile Development & QA",
            answer: "In the development phase, we transform designs into a fully functional app. Following the Scrum methodology, our team delivers app components in cycles and conducts rigorous automated testing to ensure efficiency. Supervised by an experienced Scrum Master, this stage ensures the smooth delivery of your product.",
          },
          {
            question: "Release",
            answer: "Upon completion of development and testing, we prepare your web application for launch. After configuring the server and conducting thorough testing, we ensure the application can handle traffic before making it available to your users.",
          },
          {
            question: "Maintenance",
            answer: "Our commitment to your project doesn't end with the launch. As a full-service web development agency, we provide ongoing maintenance, including bug fixes, security updates, and technology upgrades, ensuring your app remains at its best. Ready to experience it firsthand? Partner with us and see the difference.",
          },
        ]
      },
      {
        title: "Not Quite Ready for App Development?",
        description: "Our Product Design Workshops offer a solution to clarify your vision and establish project documentation. Alternatively, we can organize a Product Discovery session to assess your app concept and strategize the project from inception."
      },
      {
        title: "Tech Stack for All Web Development Needs",
        description: "Whether you're enhancing your existing app or embarking on a new project, we've got you covered. Our team will guide you in selecting the most suitable technologies and tools to create a product tailored to your needs, choosing from the following options:",
        serviceDetailsSecArraySubDetails: [
          {
            question: "Programming Languages",
            answer: "Java, Python, JavaScript, TypeScript",
          },
          {
            question: "Databases",
            answer: "MongoDB, Redis, Indexed DB",
          },
          {
            question: "Frameworks",
            answer: "React, Next.js, Nest.js, Node.js, Spring, Hibernate, Django, Redux Toolkit (RTK Query), Jest, Cypress",
          },
          {
            question: "Architecture",
            answer: "React Context / Redux, Saga, Server-Side Rendering (SSR), Single Page Application (SPA), Micro Frontends, Atomic Design.",
          },
        ]
      },
    ]
  },
  // Add more services as needed
];