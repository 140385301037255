import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const InnerPageBanner = ({ backgroundImage, title }) => {
    return (
        <section className="innerPageBanner-sec">
            <video className="background-video" autoPlay muted loop>
                <source src={backgroundImage} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="ah-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="description mb-0">
                            <h1>{title}</h1>
                            <div className="breadcrumb mb-0">
                                <Link className="d-link" to={'/'}>Home</Link>
                                <span className="breadcrumb-default-delimiter"></span>
                                <span className="current">{title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InnerPageBanner;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import './style.scss';

// const InnerPageBanner = ({backgroundImage, title}) => {
//     return (
//         <>
//             <section className="innerPageBanner-sec bg-theme bg-cover" style={{ backgroundImage: `url(${backgroundImage})` }}>
//                 <div className="ah-container">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="description mb-0">
//                                 <h1>{title}</h1>
//                                 <div className="breadcrumb mb-0">
//                                     <Link className="d-link" to={'/'}>Home</Link>
//                                     <span className="breadcrumb-default-delimiter"></span>
//                                     <span className="current">{title}</span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default InnerPageBanner;